


  
  input[type="button"] {
    border: none;
    background: cornflowerblue;
    color: white;
    padding: 12px 24px;
    margin: 8px;
    font-size: 24px;
    border-radius: 8px;
    cursor: pointer;
}

body, html {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
    background-color: #f0f2f5;
    height: 100%;
  }
  
  .App {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }
  
  form {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    width: 100%;
    max-width: 600px;
  }
  
  input[type="text"], input[type="email"], textarea, select {
    width: 97%;
    padding: 10px;
    margin-top: 8px;
    margin-bottom: 16px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  label {
    display: block;
    margin-top: 20px;
    color: #333;
    font-size: 16px;
  }
  
  textarea {
    height: 100px;
    resize: vertical;
  }
  
  button {
    width: 100%;
    padding: 12px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  input[type="checkbox"] {
    margin-right: 10px;
  }
  
  /* Styling for form headings or sections if needed */
  .form-section-heading {
    padding-top: 20px;
    font-size: 20px;
    color: #444;
  }
  
  /* Ensure the form is centered with appropriate spacing on smaller screens */
  @media (max-width: 768px) {
    form {
      padding: 15px;
    }
  }
  /* Update this in your CSS file */
.blur *:not(div) {
    filter: blur(15px); /* Increased from 8px to 15px */
  }

  .login-button {
    position: fixed;
    top: 20px;
    right: 20px;
    padding: 5px 10px;
    font-size: 14px;
    width: auto; /* Override the width: 100% from generic button styles */
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .login-button:hover {
    background-color: #0056b3;
  }
  
  body.blur > .content {
    filter: blur(8px);
  }
  
  .no-scroll {
    overflow: hidden;
  }
  
  .blur-background > .content {
    filter: blur(12px);
  }
  
  .login-modal, .thank-you-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1050; /* Ensure this is higher than the content's z-index */
    /* Style your modal further as needed */
  }
  